// Colors

// testColor
$test: #D32F2F;

// Black

$black-1: #2C2C2C;
$black-2: #00000029;
$black-3: #ec7ba114;
$black-4: #505D69;
$black-5: #5B5B5C;
$black-6: #666667;

// Blue

$blue-1: #1AA1B9;
$blue-2: #BBE3EA;
$blue-3: #D2ECF1;

// Gray

$gray-1: #EFF2F7;
$gray-2: #AFA8A8;
$gray-3: #DEDEDE;

// Orange

$orange-1: #FFC165;

// Pink

$pink-1: #FF6FA9;
$pink-2: #EC7BA1;
$pink-3: #FADFE9;

// Red

$red-1: #F53D61;
$red-2: #FDD1DA;
$red-3: #D32F2F;

// White

$white-1: #EAD7DC;
$white-2: #FAFAFA;